<template>
	<div class="wscn-http404-container" :style="bgImg">
		<div class="http404-text">
			<h4>{{ $t('message.errorPageTip') }}</h4>
			<div class="http404-text-btn">
				<a href='#/'>{{ $t('home.backHome') }}</a>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Page404',
	data () {
		return {
			bgImg : {
				"background":"url("+require('@/assets/404_images/404New.png')+") center center no-repeat",
			}
		}
	},
	computed: {
		
	}
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
$http404color:#6ab1f8;

.wscn-http404-container{
	position:absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;

	.http404-text{
		position: absolute;
		min-width: 200px;
		right: 25%;
		top: 50%;
		font-size: 14px;
		h4{
			font-family: 微软雅黑;
			color: $http404color;
			font-weight: 400;

		}

		.http404-text-btn{

			a{
				display: inline-block;
				padding: 5px 15px;
				border-radius: 15px;
				border: 1px solid $http404color;
				color:$http404color;
				
			}
		}
	}

	
}

</style>
