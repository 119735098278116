var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wscn-http404-container", style: _vm.bgImg },
    [
      _c("div", { staticClass: "http404-text" }, [
        _c("h4", [_vm._v(_vm._s(_vm.$t("message.errorPageTip")))]),
        _c("div", { staticClass: "http404-text-btn" }, [
          _c("a", { attrs: { href: "#/" } }, [
            _vm._v(_vm._s(_vm.$t("home.backHome"))),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }